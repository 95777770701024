function lhnJsSdkEmbeddedOpened() {
    // This function will be called when the embedded window is opened
    // NOTE: !!!!!!!!!!!!!!!!!!!!!!!! this is only included for future reference !!!!!!!!!!!!!!!!!!!!!!!!!
    // do we need to set initial focus to something inside the embedded chat window?
    // IF SO, or we need to do other manipulations to the LH popup, it should go here most likely
  }

  function lhnJsSdkEmbeddedClosed() {
    if (
      document.getElementById("openLiveChatBtn")
    ) {
      document.getElementById("openLiveChatBtn").focus();
    }
  }

  function HandleChatPopup() {
    if (lhnJsSdk && 
      document
        .getElementById("lhnHelpOutCenter") && 
      document
        .getElementById("lhnHelpOutCenter").classList.contains("lhnActive")
    ) {
      // chat popup is open so close it
      lhnJsSdk.closeHOC();
    } else {
      // open the embedded chat popup
      lhnJsSdk.openHOC();
    }
  }

function doLiveChat() {
    if (
      document.getElementById("lhnHocButton") === undefined ||
      document.getElementById("lhnHocButton") == null
    ) {
      (function (d, s) {
        var lhnjs = d.getElementsByTagName(s)[0];
        var newjs = d.createElement(s);
        newjs.src =
          "https://developer.livehelpnow.net/js/sdk/lhn-jssdk-current.min.js";
        lhnjs.parentNode.insertBefore(newjs, lhnjs);
      })(document, "script");
    }
  }

(function (d, s) {
  var newjs, lhnjs = d.getElementsByTagName(s)[0];
  newjs = d.createElement(s);
  newjs.src = 'https://cdn.botframework.com/botframework-webchat/latest/webchat.js';
  lhnjs.parentNode.insertBefore(newjs, lhnjs);
}(document, "script"));

(function (d, s) {
  var lhnjs = d.getElementsByTagName(s)[0];
  var simpleUpdate = d.createElement(s);
  simpleUpdate.src = 'https://unpkg.com/simple-update-in/dist/simple-update-in.production.min.js';
  lhnjs.parentNode.insertBefore(simpleUpdate, lhnjs);
}(document, "script"));

var interval;

const webchat = (function () {
  var _claimdata;
  var _chatconfig = null, _liveChatInitialized = false, _loggOffData = {}, timeoutReminderid = 0, timeoutSessionid = 0, interval;
  var _liveChatConfig = null;
  var _liveChatConfiguration = [];
  var sessionTimeOut;
  var reminderInterval;
  var _sessionActive = false;
  var _directLineUrl;
  var instrumentationkey = "";
  var feedbackRatingSelected = null;
  var isSurveyEnabled = false;
  function loadAppInsight(id) {
    var appInsights = window.appInsights || function (a) {
      function b(a) {
        c[a] = function () {
          var b = arguments;
          c.queue.push(function () { c[a].apply(c, b) })
        }
      }
      var c = { config: a }, d = document, e = window; setTimeout(function () {
        var b = d.createElement("script");
        b.src = a.url || "https://az416426.vo.msecnd.net/scripts/a/ai.0.js",
          d.getElementsByTagName("script")[0].parentNode.appendChild(b)
      });
      try { c.cookie = d.cookie } catch (a) { } c.queue = [];
      for (var f = ["Event", "Exception", "Metric", "PageView", "Trace", "Dependency"]; f.length;)
        b("track" + f.pop()); if (b("setAuthenticatedUserContext"), b("clearAuthenticatedUserContext"), b("startTrackEvent"),
          b("stopTrackEvent"), b("startTrackPage"), b("stopTrackPage"), b("flush"),
          !a.disableExceptionTracking) {
        f = "onerror", b("_" + f);
        var g = e[f]; e[f] = function (a, b, d, e, h) { var i = g && g(a, b, d, e, h); return !0 !== i && c["_" + f](a, b, d, e, h), i }
      } return c
    }
      ({ instrumentationKey: instrumentationkey });
    window.appInsights = appInsights, appInsights.queue && 0 === appInsights.queue.length && appInsights.queue.push(function () {
      appInsights.context.addTelemetryInitializer(function (envelope) {
        var telemetryItem = envelope.data.baseData;

        // To set custom properties:
        telemetryItem.properties = telemetryItem.properties || {};
        telemetryItem.properties["ConversationId"] = id;
      });
    });
    appInsights.trackPageView("Carey 2.0 user location trace", id);
  }


  window.addEventListener('beforeunload', () => {
    _endConversation();
  });
  const headers = { 'Content-Type': 'application/json', 'Accept': '*/*', 'Access-Control-Allow-Origin': '*' };
  const _initlaizeLiveHelpInfra = (userInfo) => {
    window.lhnJsSdkReady = () => {
      applyConfigToLiveChat(userInfo);
    }
    if (typeof (window.lhnJsSdkInit) === 'undefined'){
      window.lhnJsSdkInit = function () {
        window.lhnJsSdk.setup = {
          application_id: _liveChatConfig.appId,
          application_secret: _liveChatConfig.secret
        };
        window.lhnJsSdk.controls = [{
          type: _liveChatConfig.type,
          id: _liveChatConfig.id
        }];
        window.lhnJsSdk.fieldData = {};
        window.lhnJsSdk.options = {
          domain_policy: "strict",
  
        };
      };

      (function (d, s) {
        var newjs, lhnjs = d.getElementsByTagName(s)[0];
        newjs = d.createElement(s);
        newjs.src = 'https://developer.livehelpnow.net/js/sdk/lhn-jssdk-current.min.js';
        lhnjs.parentNode.insertBefore(newjs, lhnjs);
      }(document, "script"));
    }
    
    window.lhnJsSdkEmbeddedClosed = function () {
      var closeMessage = document.getElementById("showCloseMessage");
      if (closeMessage) {
        closeMessage.style.display = 'none';
      }

      document.getElementById('chatcontainer').style.display = "block";
    };
    
    window.lhnJsSdkChatEnded = (chat_session_id) => {
      // Issue - Carey prompted user with ready to time out even if they were active.
      // Solution - reset the reminder interval when live chat ends.
      clearReminderMessage();
      clearTimeout(interval);
      RestartBotDialog();
    };

    window.lhnJsSdkEmbeddedOpened = () => {
      // This function will be called when the embedded window is opened

      var lhnHelpOutCenter = document.getElementById('lhnHelpOutCenter');
      if (lhnHelpOutCenter) {
        //lhnHelpOutCenter.classList.add("sedgwick-livechat-container");
        lhnHelpOutCenter.addEventListener('keyup', EscapeFromLiveChatWindow);
      }

      var closeBtn = document.querySelector('.lhn-chat-properties-btn > .lhnWindow-header-closer');
      if (closeBtn) {
        closeBtn.addEventListener("click", closebuttonprovider);
        closeBtn.setAttribute('tabindex', '1');
        closeBtn.addEventListener("keyup", onkeyupLiveChatCloseBtn);
      }

      setTimeout(function () {
        var livechattextbox = document.querySelector('#lhnChatMessageBox textarea');
        if (livechattextbox) {
          livechattextbox.setAttribute("autocomplete", "off");
          livechattextbox.setAttribute("placeholder", "Type your message");
          livechattextbox.focus();
        }
        // var smileyDiv = document.querySelector('.lhnHocEmojiAction');
        // if (smileyDiv) {
        //   smileyDiv.style.display = 'none';
        // }
      }, 5000);
    };
  };
  const _styleOptions = {
    botAvatarInitials: 'Carey',
    accent: '#00809d',
    botAvatarBackgroundColor: "#FFFFFF",
    botAvatarImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAADDUlEQVRIie3WW4iVVRQH8J8zVlo0SGkiDuJY3hLCUrykUz4lFdo8DWUIZZQgaEEv1jx4efBBoYfogmgXCYJ8qIeGwDDKDAkLMcnQUsciEi/T8V6Ct4e1DueMnnPm87XccPjO3nut9V977bX+a3Nz/NfHgOtW3jvQSH4ypqMNf+EsLuIEDubamZqaL9zXZzqwoIMPYikmYge+xk4cy/27MRp3YFg6crqRwSLAi7Ea27AQtULSm79mbMUFvIQ/6hlt6gd0Kd7Fh+isA1o9XsEczMXnGFNPsNGJO/EmPsWKqvU70Y6xIrSHE+QMHq6SewCbMB+losD3Yh1OJei/uT4Db6fRgeKuj2IK1mMZjovrgdl4EWuvBagX6mcxCt34Odc6ROgfwm/YiOF4IoFfwwK8jg1VthajtQjwUMzL/5/ld44I+VQRiZ+wCPeLRPoTz+V8LbqwN3XH4NEiwG15qkv4PteWiZpvwq/insu6W1TutklEa2w6WB4dRYDH5/oVlRLpTUd+zNPchc34Bveks3ASg0T4P1Kp877sUQe4Jb9lVmtPg9vS6GjMxDhRZiXsT0dvSZ2RuIztOZ9QBLh6bxxuFYzVjSer9s8LQmkVJbVGXEM3vk2ZPY2MXzvKVDdAJNkeTBJlszP3/sYhPIJZgjBa8JRIpiEpV67fw0WA94v7hKdTebcgguP4Al+KDC+HtldcwQwRnV9yfVR+9xUB7sGu/D8RS7BcdJ8OcfJPcCRlLuMrQSqD8L7Ih2ZMS5ktRYBPCAqUyitFGD8Q1zAEb2BEfhfhO5FInXhZMF2bSMweEaV+geFjlYbQIhJmgujFR3G7oMyRojG8KnrzVpxLvS6RJ+vV6FKNHgKd6UBzzv/B4yLZWhOoXZDJdn0zeG6e8ofUKd3IQ2CzoM+30sEjIjtLKtnaU0NvkuDqA4K7r+tM9N+P38HzIlSr8HsD2dvwjHih7MVjouRqjiIvkE0iZPsE9Q0W5XJJZHGbaCLzRbfqEie+0Mho0TdXuS5L4n21MB0YnDbKSbRDhZ9vjv/5uAp877LUC6J1JQAAAABJRU5ErkJggg==',
    hideUploadButton: true,
    bubbleTextColor: '#000000; font-size:14px;',
    bubbleBackground: '#fff',
    bubbleBorderColor: '#eceff1',
    bubbleBorderRadius: 5,
    bubbleBorderStyle: 'solid',
    bubbleBorderWidth: 1,
    bubbleFromUserBackground: '#DEF0FA; font-size:14px;',
    bubbleFromUserBorderColor: '#DEF0FA',
    bubbleFromUserBorderRadius: 5,
    bubbleFromUserBorderStyle: '',
    bubbleFromUserBorderWidth: 1,
    suggestedActionBackgroundColor: '#fff',
    suggestedActionBorderColor: '#e2f6e8; box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.19) !important;',
    suggestedActionBorderRadius: 5,
    suggestedActionBorderStyle: 'solid',
    suggestedActionBorderWidth: 1,
    suggestedActionHeight: 30,
    suggestedActionImageHeight: 30,
    suggestedActionLayout: 'stacked',
    suggestedActionTextColor: '#007DBC; font-size:14px;',
    suggestedActionsStackedHeight: 125,
    suggestedActionsStackedOverflow: 'none; text-align: end;',
    suggestedActionsStackedLayoutButtonMaxHeight: 30,
    suggestedActionsStackedLayoutButtonTextWrap: true,

  };
  const applyConfigToLiveChat = (userInfo) => {
    var parsedClaimData = JSON.parse(userInfo.claimData);
    if (_liveChatConfiguration.length > 0) {
      lhnJsSdk.options.dept = _liveChatConfiguration.find(d => d.config_key.toLowerCase() == 'dept').config_value;
      lhnJsSdk.options.chatWindowId = _liveChatConfiguration.find(d => d.config_key.toLowerCase() == 'chatwindowid').config_value;
      lhnJsSdk.refresh_visitor();
      lhnJsSdk.refresh();

      _liveChatConfiguration.filter(d => d.config_key.toLowerCase() != 'dept' && d.config_key.toLowerCase() != 'chatwindowid')
        .forEach((conf) => {
          lhnJsSdk.fieldData[conf.config_key] = eval(conf.config_value);
        });
      lhnJsSdk.fieldData.auto_submit = true;
    }
    else {
      showLiveChatAccessDeniedMessage();
      return false;
    }

    var delayInMilliseconds = 1000; //1 second

    setTimeout(function () {
      if (window.lhnJsSdk.isOnline === false) offlineMessage();
      else {
        setTimeout(() => {
          document.getElementById('chatcontainer').style.display = "none";
        }, 1000);
        window.lhnJsSdk.openHOC();
      }
    }, delayInMilliseconds);
  }
  const _initLiveChat = (userInfo) => {
    if (_liveChatInitialized) {
      applyConfigToLiveChat(userInfo);
    }
    _initlaizeLiveHelpInfra(userInfo);
    _liveChatInitialized = true;

  };
  function openLiveChat(userInfo, step, config) {
    if (!_liveChatInitialized) _initLiveChat(userInfo);
    else {
      applyConfigToLiveChat(userInfo);
    }
  };

  var store = null;
  function createStore() {
    store = null;
    store = window.WebChat.createStore({}, function (dispatch) {
      return function (next) {
        return function (action) {
          if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
            setChatTimeoutInterval();
            if (instrumentationkey != "null") {
              var conversationId = action?.payload.directLine.conversationId;
              if (conversationId) {
                // user location tracing using app Insights.
                loadAppInsight(conversationId);
              }
            }
            action = window.simpleUpdateIn(
              action,
              ['payload', 'activity', 'channelData', 'claimData'],
              () => JSON.stringify(_claimdata)
            );
          }
          else if (action.type === 'DIRECT_LINE/POST_ACTIVITY') {
            if (action?.payload.activity.type == "message") {
              // Important info - Disable Push buttons in all previous Bot responses, after sending latest message by the User
              DisablePushButtons();
            }
            setChatTimeoutInterval();
          }
          else if (action.type === 'WEB_CHAT/SEND_MESSAGE') {
            clearReminderMessage();
            clearTimeout(interval);
            DisableSendBox();
          }
          else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
            EnableSendBox();
            if (action?.payload.activity.type == "message") {
              FocusSendBox();
            }

            // Message sent by the bot
            var userInfo = action?.payload.activity.channelData;
            if (userInfo) {
              if (userInfo?.step == "startlivechat") {
                openLiveChat(userInfo, null);
              }
              if (userInfo?.step == "endchatbot") {
                showCloseModel();
              }
            }

            clearInterval(interval);
            interval = setTimeout(function () {
              // Notify bot the user has been inactive
              dispatch.dispatch({
                type: 'WEB_CHAT/SEND_EVENT',
                payload: {
                  name: 'inactive',
                  value: ''
                }
              });
            }, 3000)
          }
          else {
          }


          if (action?.payload?.channelData?.claimData == undefined || action?.payload?.channelData?.claimData == null) {
            action = window.simpleUpdateIn(
              action,
              ['payload', 'activity', 'channelData', 'claimData'],
              () => JSON.stringify(_claimdata)
            );
          }
          return next(action)
        }
      }
    });
  }

  function offlineMessage() {
    showLiveAgentOfflineMessage();
  }

  function clearReminderMessage() {
    if (timeoutReminderid != 0) {
      clearTimeout(timeoutReminderid);
      //reminderSessionTimeout();
      var element = document.getElementById("showErrorMessage");
      if (element) {
        element.innerHTML = "";
        element.className = "";
      }
      timeoutReminderid = 0;
    }
  }
  function reminderSessionTimeout() {
    var element = document.getElementById("showErrorMessage");
    if (element) {
      element.innerHTML = "Session will expire soon";
      element.className = "wc-session-expiry-content";
      timeoutReminderid = setTimeout(
        function () {
          //console.log('Session Expired');
        }.bind(this),
        (sessionTimeOut - reminderInterval) * 60 * 1000
      );
    }
  }
  function setChatTimeoutInterval() {

    if (timeoutReminderid == 0 && reminderInterval != undefined) {
      timeoutReminderid = setTimeout(
        function () {
          reminderSessionTimeout();
        }.bind(this),
        reminderInterval * 60 * 1000
      );
    }
    if (timeoutSessionid == 0 && sessionTimeOut != undefined) {
      timeoutSessionid = setTimeout(
        function () {
          CloseSession();
          timeoutReminderid = 0;
          timeoutSessionid = 0;
        }.bind(this),
        sessionTimeOut * 60 * 1000
      );
    }
  }
  function showLiveChatAccessDeniedMessage() {
    var element = document.getElementById("showErrorMessage");
    if (element) {
      element.innerHTML = "You don't have access to the Live chat.";
      element.className = "wc-session-expiry-content";
    }

    setTimeout(
      function () {
        var element = document.getElementById("showErrorMessage");
        if (element) {
          element.innerHTML = "";
          element.className = "";
        }
      }, 5000);

    RestartBotDialog();
  }

  function RestartBotDialog() {
    if (!store) return;
    // This event will intimate bot to restart the dialog
    store.dispatch({
      type: 'WEB_CHAT/SEND_MESSAGE_BACK',
      payload: { text: 'livechatend' }
    });
  }
  function IntimateBotOfClientError() {
    if (!store) return;
    // This event will intimate bot to restart the dialog
    store.dispatch({
      type: 'WEB_CHAT/SEND_MESSAGE_BACK',
      payload: { text: 'clienterror' }
    });
  }
  function DisableSendBox() {
    var sendbox = document.getElementsByClassName('webchat__send-box-text-box__input');
    if (sendbox && sendbox.length > 0) {
      sendbox[0].setAttribute("disabled", true);
      sendbox[0].setAttribute("placeholder", "Please wait...");
    }

    var btnsend = document.getElementsByClassName('webchat__send-button');
    if (btnsend && btnsend.length > 0) {
      btnsend[0].setAttribute("disabled", true);
    }

  }

  function EnableSendBox() {
    var sendbox = document.getElementsByClassName('webchat__send-box-text-box__input');
    if (sendbox && sendbox.length > 0) {
      sendbox[0].removeAttribute("disabled");
      sendbox[0].setAttribute("placeholder", "Type your message");
    }
    var btnsend = document.getElementsByClassName('webchat__send-button');
    if (btnsend && btnsend.length > 0) {
      btnsend[0].removeAttribute("disabled");
    }

  }

  function FocusSendBox() {
    var sendbox = document.getElementsByClassName('webchat__send-box-text-box__input');
    if (sendbox && sendbox.length > 0) {
      sendbox[0].focus();
    }

  }

  function DisablePushButtons() {
    var els = document.getElementsByClassName("ac-pushButton");
    if (els && els.length > 0) {
      for (i = 0; i < els.length; i++) {
        var buttonsChoice = document.getElementsByClassName('ac-pushButton')[i];
        buttonsChoice.setAttribute('style', 'opacity:0.3;pointer-events: none;');
        buttonsChoice.setAttribute('disabled', true);
      }
    }
  }


  function onkeyupLiveChatCloseBtn(e) {
    if (e && e.key === "Enter") {
      var closeBtn = document.querySelector('.lhn-chat-properties-btn > .lhnWindow-header-closer');
      if (closeBtn)
        closeBtn.click();
    }
  };

  function EscapeFromLiveChatWindow(e) {
    if (e && e.key === "Escape") {
      var livechatContainer = document.getElementById('lhnHelpOutCenter');
      if (livechatContainer)
        if (livechatContainer.classList.contains('lhnActive')) {
          var closeBtn = document.querySelector('.lhn-chat-properties-btn > .lhnWindow-header-closer');
          if (closeBtn) closeBtn.click();
          var endchatbtn = document.querySelector('.lhnPromptButtons > .lhnPromptAccept');
          if (endchatbtn) {
            endchatbtn.setAttribute('tabindex', 1);
            endchatbtn.focus();
          }
        }
    }
  };

  function closebuttonprovider() {
    lhnJsSdk.options.dept = '';
    lhnJsSdk.options.chatWindowId = '';
    lhnJsSdk.refresh();
    lhnJsSdk.refresh_visitor();
    
    const livechatContainer = document.getElementById("lhnHelpOutCenter");
    if (livechatContainer) {
      livechatContainer.classList.add("lhnActive");
      //livechatContainer.classList.add("sedgwick-livechat-container");
    }

    var element = document.querySelector('.lhnPrompt.closechat');
    if (element) { element.style.display = 'block'; }
  };


  const onKeyUp = (e) => {
    if (e && e.key === "Enter") showCloseModel();
  }

  const showLiveAgentOfflineMessage = () => {
    if (!store) return;
    // This event will intimate bot about offline status of agents
    store.dispatch({
      type: 'WEB_CHAT/SEND_MESSAGE_BACK',
      payload: { text: 'livechatoffline' }
    });
  };

  const CloseYes = () => {
    _endConversation();
  };

  const CloseNo = () => {
    document.getElementById("showCloseMessage").style.display = "none";
    FocusSendBox();
  }
  const showCloseModel = () => {
    if (isSurveyEnabled) {
      feedbackRatingSelected = null;
      document.getElementById("showCloseMessage").style.display = 'block';
      var icthumbsup = document.getElementById('icthumbsup');

      if (icthumbsup) {
        icthumbsup.focus();
      }
    }
    else {
      CloseSession();
    }
  };

  function onRatingClick(action) {
    feedbackRatingSelected = null;
    var icthumbsup = document.getElementById("icthumbsup");
    var icthumbsdown = document.getElementById('icthumbsdown');
    if (icthumbsup && icthumbsdown) {
      if (action == 'up') {

        if (icthumbsup.style.fill == 'rgb(176, 196, 222)') {
          icthumbsup.style.fill = "rgb(0, 125, 188)";

          icthumbsdown.style.fill = "rgb(176, 196, 222)";

          feedbackRatingSelected = 1;
        }
        else {
          icthumbsup.style.fill = "rgb(176, 196, 222)";
          feedbackRatingSelected = null;
        }

      }
      else if (action == 'down') {
        if (icthumbsdown.style.fill == 'rgb(176, 196, 222)') {
          icthumbsdown.style.fill = "rgb(0, 125, 188)";

          icthumbsup.style.fill = "rgb(176, 196, 222)";

          feedbackRatingSelected = 0;
        }
        else {
          icthumbsdown.style.fill = "rgb(176, 196, 222)";
          feedbackRatingSelected = null;
        }

      }
    }
    var surveydvfeedback = document.getElementById('dvfeedback');
    if (feedbackRatingSelected == 0 || feedbackRatingSelected == 1) {
      if (surveydvfeedback) {
        surveydvfeedback.style.display = "block";
      }
    }
    else {
      if (surveydvfeedback) {
        surveydvfeedback.style.display = "none";
      }
    }

  }

  async function SubmitFeedback() {
    var txtFeedback = document.getElementById("txtfeedback").value;
    var dataforFeedback = {
      "conversationId": _loggOffData.ConversationId,
      "rating": feedbackRatingSelected,
      "feedback": txtFeedback
    };
    const res = await fetch(_chatconfig.webchat.baseApiUrl + 'SubmitFeedback',
      {
        "method": "POST",
        "headers": {
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'Access-Control-Allow-Origin': '*'
        },
        "body": JSON.stringify(dataforFeedback)
      }
    );
    const response = await res.status;
  }

  function SaveFeedbackAndCloseSession() {
    if (feedbackRatingSelected == 1 || feedbackRatingSelected == 0) {
      SubmitFeedback();
    }
    _endConversation();
    document.getElementById('chatcontainer').style.display = "none";
    document.getElementById("showCloseMessage").style.display = "block";
  }
  function CloseSession() {
    _endConversation();
    document.getElementById('chatcontainer').style.display = "none";
    document.getElementById("showCloseMessage").style.display = "block";
  }


  const generateDom = () => {
    var parent = document.createElement("div");
    parent.addEventListener('keyup', function (e) {
      if (e && e.key === "Escape") {
        var ele1 = document.getElementById('lhnHelpOutCenter');
        if (ele1) {
          if (!ele1.classList.contains('lhnActive')) {
            showCloseModel();
          }
          else {
            document.getElementById("showCloseMessage").style.display = 'none';
          }
        }
        else {
          showCloseModel();
        }
      }
    });
    parent.setAttribute("id", "chatcontainer");
    parent.classList.add("webchatContainer");
    parent.classList.add("align-right");
    var webChatHeader = document.createElement("div");
    webChatHeader.className = "webchatheader";
    var webChatWrapperHeaderDv = document.createElement("div");
    webChatWrapperHeaderDv.style = "margin:3px 0 5px;";

    var webChatHeaderSpan = document.createElement("div");
    webChatHeaderSpan.style.float = "left";
    var webChatHeaderText = document.createElement("span");
    webChatHeaderText.textContent = "Chat with us";
    webChatHeaderSpan.appendChild(webChatHeaderText);
    webChatWrapperHeaderDv.appendChild(webChatHeaderSpan);
    var webChatHeaderCloserOuter = document.createElement("div");

    webChatHeaderCloserOuter.addEventListener("click", showCloseModel);
    webChatHeaderCloserOuter.onkeyup = onKeyUp;
    webChatHeaderCloserOuter.tabIndex = 1;
    webChatHeaderCloserOuter.className = "web-chat-container-chatbox-classic-header-top-properties web-chat-properties-btn";
    webChatHeaderCloserOuter.style.float = "right";
    var webChatHeaderCloserInner = document.createElement("div");
    webChatHeaderCloserInner.className = "webWindow-header-closer";
    var webChatHeaderCloserSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    webChatHeaderCloserSvg.style.width = "19px";
    webChatHeaderCloserSvg.style.height = "19px";
    webChatHeaderCloserSvg.style.fill = "none";
    webChatHeaderCloserSvg.setAttribute("viewBox", "1 1 22 22");

    webChatHeaderCloserSvg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    var webChatHeaderCloserSvgPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
    webChatHeaderCloserSvgPath.setAttribute("d", "M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z");
    webChatHeaderCloserSvgPath.style.fill = "#25506B";
    webChatHeaderCloserSvg.appendChild(webChatHeaderCloserSvgPath);
    webChatHeaderCloserInner.appendChild(webChatHeaderCloserSvg);
    webChatHeaderCloserOuter.appendChild(webChatHeaderCloserInner);
    webChatWrapperHeaderDv.appendChild(webChatHeaderCloserOuter);
    webChatHeader.appendChild(webChatWrapperHeaderDv);
    parent.appendChild(webChatHeader);
    var dvShowErrorMessage = document.createElement("div");
    dvShowErrorMessage.setAttribute("id", "showErrorMessage");
    parent.appendChild(dvShowErrorMessage);
    var dvShowCloseMessage = document.createElement("div");
    dvShowCloseMessage.className = "modal";
    dvShowCloseMessage.setAttribute("id", "showCloseMessage");
    var dvShowCloseMessageModalContent = document.createElement("div");
    dvShowCloseMessageModalContent.className = "modal-content";
    var closeConfirmSpan = document.createElement("span");
    closeConfirmSpan.textContent = "How do you rate my service ?";
    closeConfirmSpan.setAttribute("id", "closeConfirmSpan");
    dvShowCloseMessageModalContent.appendChild(closeConfirmSpan);

    var br1 = document.createElement("br");
    var br2 = document.createElement("br");
    dvShowCloseMessageModalContent.appendChild(br1);
    dvShowCloseMessageModalContent.appendChild(br2);

    var dvFeedbackActiondv = document.createElement("div");
    dvFeedbackActiondv.className = "feedback-action-dv";

    var thumbsUpAnchor = document.createElement("a");
    thumbsUpAnchor.className = "thumbs-up";
    thumbsUpAnchor.setAttribute("tabindex", "2");
    thumbsUpAnchor.onclick = function () {
      onRatingClick('up');
    };

    var svgThumsUp = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svgThumsUp.style.width = "24px";
    svgThumsUp.style.height = "24px";
    svgThumsUp.style.fill = "none";
    svgThumsUp.setAttribute("viewBox", "0 0 122.88 104.19");
    svgThumsUp.setAttribute("xmlns", "http://www.w3.org/2000/svg");

    var path1 = document.createElementNS("http://www.w3.org/2000/svg", "path");
    path1.setAttribute("id", "icthumbsup");
    path1.setAttribute("d", "M62.63,6.25c0.56-2.85,2.03-4.68,4.04-5.61c1.63-0.76,3.54-0.83,5.52-0.31c1.72,0.45,3.53,1.37,5.26,2.69 c4.69,3.57,9.08,10.3,9.64,18.71c0.17,2.59,0.12,5.35-0.12,8.29c-0.16,1.94-0.41,3.98-0.75,6.1h19.95l0.09,0.01 c3.24,0.13,6.38,0.92,9.03,2.3c2.29,1.2,4.22,2.84,5.56,4.88c1.38,2.1,2.13,4.6,2.02,7.46c-0.08,2.12-0.65,4.42-1.81,6.87 c0.66,2.76,0.97,5.72,0.54,8.32c-0.36,2.21-1.22,4.17-2.76,5.63c0.08,3.65-0.41,6.71-1.39,9.36c-1.01,2.72-2.52,4.98-4.46,6.98 c-0.17,1.75-0.45,3.42-0.89,4.98c-0.55,1.96-1.36,3.76-2.49,5.35l0,0c-3.4,4.8-6.12,4.69-10.43,4.51c-0.6-0.02-1.24-0.05-2.24-0.05 l-39.03,0c-3.51,0-6.27-0.51-8.79-1.77c-2.49-1.25-4.62-3.17-6.89-6.01l-0.58-1.66V47.78l1.98-0.53 c5.03-1.36,8.99-5.66,12.07-10.81c3.16-5.3,5.38-11.5,6.9-16.51V6.76L62.63,6.25L62.63,6.25L62.63,6.25z M4,43.02h29.08 c2.2,0,4,1.8,4,4v53.17c0,2.2-1.8,4-4,4l-29.08,0c-2.2,0-4-1.8-4-4V47.02C0,44.82,1.8,43.02,4,43.02L4,43.02L4,43.02z M68.9,5.48 c-0.43,0.2-0.78,0.7-0.99,1.56V20.3l-0.12,0.76c-1.61,5.37-4.01,12.17-7.55,18.1c-3.33,5.57-7.65,10.36-13.27,12.57v40.61 c1.54,1.83,2.96,3.07,4.52,3.85c1.72,0.86,3.74,1.2,6.42,1.2l39.03,0c0.7,0,1.6,0.04,2.45,0.07c2.56,0.1,4.17,0.17,5.9-2.27v-0.01 c0.75-1.06,1.3-2.31,1.69-3.71c0.42-1.49,0.67-3.15,0.79-4.92l0.82-1.75c1.72-1.63,3.03-3.46,3.87-5.71 c0.86-2.32,1.23-5.11,1.02-8.61l-0.09-1.58l1.34-0.83c0.92-0.57,1.42-1.65,1.63-2.97c0.34-2.1-0.02-4.67-0.67-7.06l0.21-1.93 c1.08-2.07,1.6-3.92,1.67-5.54c0.06-1.68-0.37-3.14-1.17-4.35c-0.84-1.27-2.07-2.31-3.56-3.09c-1.92-1.01-4.24-1.59-6.66-1.69v0.01 l-26.32,0l0.59-3.15c0.57-3.05,0.98-5.96,1.22-8.72c0.23-2.7,0.27-5.21,0.12-7.49c-0.45-6.72-3.89-12.04-7.56-14.83 c-1.17-0.89-2.33-1.5-3.38-1.77C70.04,5.27,69.38,5.26,68.9,5.48L68.9,5.48L68.9,5.48z");
    path1.style.fill = "rgb(176, 196, 222)";
    svgThumsUp.appendChild(path1);
    thumbsUpAnchor.appendChild(svgThumsUp);

    dvFeedbackActiondv.appendChild(thumbsUpAnchor);

    var thumbsDownAnchor = document.createElement("a");
    thumbsDownAnchor.className = "thumbs-down";
    thumbsDownAnchor.setAttribute("tabindex", "3");
    thumbsDownAnchor.onclick = function () {
      onRatingClick('down');
    };

    var svgThumsDown = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svgThumsDown.style.width = "24px";
    svgThumsDown.style.height = "24px";
    svgThumsDown.style.fill = "none";
    svgThumsDown.setAttribute("viewBox", "0 0 122.88 104.19");
    svgThumsDown.setAttribute("xmlns", "http://www.w3.org/2000/svg");

    var path2 = document.createElementNS("http://www.w3.org/2000/svg", "path");
    path2.setAttribute("id", "icthumbsdown");
    path2.setAttribute("d", "M62.63,97.94c0.56,2.85,2.03,4.68,4.04,5.61c1.64,0.76,3.54,0.83,5.52,0.31c1.72-0.45,3.53-1.37,5.26-2.69 c4.69-3.57,9.08-10.3,9.64-18.71c0.17-2.59,0.13-5.35-0.12-8.29c-0.16-1.94-0.41-3.98-0.75-6.1h19.95l0.09-0.01 c3.24-0.13,6.38-0.92,9.03-2.3c2.29-1.2,4.22-2.84,5.56-4.88c1.38-2.1,2.13-4.6,2.02-7.46c-0.08-2.12-0.65-4.42-1.81-6.88 c0.66-2.76,0.97-5.72,0.54-8.32c-0.36-2.21-1.22-4.17-2.76-5.63c0.08-3.65-0.41-6.71-1.39-9.36c-1.01-2.72-2.52-4.98-4.46-6.98 c-0.17-1.75-0.45-3.42-0.89-4.98c-0.55-1.96-1.36-3.76-2.49-5.35l0,0c-3.4-4.8-6.12-4.69-10.43-4.51c-0.6,0.02-1.24,0.05-2.24,0.05 l-39.02,0c-3.51,0-6.27,0.51-8.79,1.77c-2.5,1.25-4.62,3.17-6.89,6.01l-0.58,1.66v45.52l1.98,0.53c5.03,1.36,8.99,5.66,12.07,10.81 c3.16,5.3,5.38,11.5,6.89,16.51v13.16L62.63,97.94L62.63,97.94L62.63,97.94z M4,61.17h29.08c2.2,0,4-1.8,4-4V4c0-2.2-1.8-4-4-4L4,0 C1.8,0,0,1.8,0,4v53.17C0,59.37,1.8,61.17,4,61.17L4,61.17L4,61.17z M68.9,98.72c-0.43-0.2-0.78-0.7-0.99-1.56V83.89l-0.12-0.76 c-1.61-5.37-4.01-12.17-7.55-18.1c-3.33-5.57-7.65-10.36-13.28-12.57V11.85c1.54-1.83,2.96-3.07,4.52-3.85 c1.72-0.86,3.74-1.2,6.42-1.2h39.02c0.7,0,1.61-0.04,2.45-0.07c2.56-0.1,4.17-0.17,5.9,2.27v0.01c0.75,1.06,1.3,2.31,1.69,3.71 c0.42,1.5,0.67,3.15,0.79,4.92l0.82,1.75c1.72,1.63,3.03,3.46,3.87,5.71c0.86,2.32,1.23,5.11,1.02,8.61l-0.09,1.58l1.34,0.83 c0.92,0.57,1.42,1.65,1.63,2.97c0.34,2.09-0.02,4.67-0.67,7.06l0.21,1.92c1.08,2.07,1.6,3.92,1.67,5.54 c0.06,1.68-0.37,3.14-1.17,4.35c-0.84,1.27-2.07,2.31-3.56,3.09c-1.92,1-4.24,1.59-6.66,1.69v-0.01l-26.32,0l0.59,3.15 c0.57,3.05,0.98,5.96,1.22,8.72c0.23,2.7,0.27,5.21,0.12,7.49c-0.45,6.72-3.89,12.04-7.56,14.83c-1.17,0.89-2.33,1.5-3.38,1.77 C70.04,98.92,69.38,98.94,68.9,98.72L68.9,98.72L68.9,98.72z");
    path2.style.fill = "rgb(176, 196, 222)";
    svgThumsDown.appendChild(path2);
    thumbsDownAnchor.appendChild(svgThumsDown);

    dvFeedbackActiondv.appendChild(thumbsDownAnchor);
    dvShowCloseMessageModalContent.appendChild(dvFeedbackActiondv);

    var dvfeedback = document.createElement("div");
    dvfeedback.setAttribute("id", "dvfeedback");
    dvfeedback.style.display = "none";
    var br3 = document.createElement("br");
    dvfeedback.appendChild(br3);

    var pleasureSpan = document.createElement("span");
    pleasureSpan.innerHTML = "It has been my pleasure to help you! <br /> I would appreciate your feedback.";
    dvfeedback.appendChild(pleasureSpan);
    var br5 = document.createElement("br");
    var br6 = document.createElement("br");
    dvfeedback.appendChild(br5);
    dvfeedback.appendChild(br6);

    var txtfeedback = document.createElement("textarea");
    txtfeedback.setAttribute("id", "txtfeedback");
    txtfeedback.tabIndex = 4;
    txtfeedback.setAttribute("rows", "3");
    txtfeedback.setAttribute("cols", "25");

    dvfeedback.appendChild(txtfeedback);
    dvShowCloseMessageModalContent.appendChild(dvfeedback);

    var br7 = document.createElement("br");
    var br8 = document.createElement("br");
    dvShowCloseMessageModalContent.appendChild(br7);
    dvShowCloseMessageModalContent.appendChild(br8);


    var btnYes = document.createElement("button");
    btnYes.style.marginBottom = "5px";
    btnYes.style.cursor = "pointer";
    btnYes.textContent = "Close";
    btnYes.setAttribute("id", "closeYesButton");
    btnYes.setAttribute("tabindex", "5");
    btnYes.addEventListener("click", SaveFeedbackAndCloseSession)

    dvShowCloseMessageModalContent.appendChild(btnYes);
    dvShowCloseMessage.appendChild(dvShowCloseMessageModalContent);
    parent.appendChild(dvShowCloseMessage);
    var child2 = document.createElement("div");
    child2.className = "webchatbody";
    let webchat = document.createElement("div");
    webchat.setAttribute("id", "webchat");
    webchat.className = "webchat";
    webchat.setAttribute("role", "main");
    child2.appendChild(webchat);
    parent.appendChild(child2);
    document.body.appendChild(parent);
  };
  const clearDom = () => {
    var container = document.getElementById("chatcontainer");
    if (container) {
      document.body.removeChild(container);
    }
  }
  const _initChat = (data, claimData, tokenGenerationApi) => {
    if (_sessionActive == true) {
      showCloseModel();
      return;
    }
    _claimdata = claimData;

    _tokenGenerationRequestData = data;
    document.head.insertAdjacentHTML("beforeend", `<style>
        /** LiveHelpNow CSS starts */
        #lhnHelpOutCenter {
            height: 0px !important;
        }

        @media screen and (max-width: 500px) and (min-width: 0px) and (orientation: portrait) {
            #lhnHelpOutCenter .lhn-chat-container-chatbox-classic {
                height: 70%;
                max-height: 70%;
            }
        }

        #lhnHelpOutCenter .lhn-chat-container-chatbox-classic {
            bottom: 10%;
            height: 60%;
            position: fixed;
            font-family: 'Calibri','Helvetica Neue','Arial','sans-serif' !important;
            max-height: 575px;
            min-height: 450px;
        }

        .lhnHocSection {
            top: 35% !important;
            background-color: #fff;
            border-radius: 3px;
            height: 90%;
            overflow: auto;
            width: 100%;
            position: relative;
        }

        #lhnHelpOutCenter #hocSyschat.lhnHocSection {
            background-color: #fff;
            border-radius: 3px;
            overflow: auto;
            width: 100%;
            position: relative;
            top: 10% !important;
            max-height: 575px;
            height: 103%;
        }

        #hocContainer {
            height: 90% !important;
            bottom: 10% !important;
            position: relative !important;
            max-height: 575px;
        }

        .lhnWindow-modern_2022 .no-menu #hocContainer {
            height: 90% !important;
            bottom: 10% !important;
            position: relative !important;
            max-height: 575px;
        }

        .lhn-chat-container-chatbox-classic-header {
            background-color: #007DBC;
            box-shadow: 0 1px rgba(0, 0, 0, 0.2);
            box-sizing: content-box;
            color: #ffffff;
            font-weight: 500;
            height: 15%;
            left: 0;
            letter-spacing: 0.5px;
            position: relative;
            right: 0;
            top: 0;
            z-index: 1;
            border-radius: 4px !important;
        }

        div.lhnPrompt {
            width: 375px;
        }

        .lhnCustomerMessage .lhnWindow-message {
            background-color: #DEF0FA;
            border-radius: 5px;
            border: 1px solid #DEF0FA;
            color: #000000;
            font-size: 14px;
        }

        .lhnSystemMessage .lhnWindow-message {
            background-color: #fff;
            border-radius: 5px;
            border: 1px solid #eceff1;
            color: #000000;
            font-size: 14px;
        }

        .lhnOperatorMessage .lhnWindow-message {
            background-color: #fff;
            border-radius: 5px;
            border: 1px solid #eceff1;
            color: #000000;
            font-size: 14px;
        }

        .wc-header {
            z-index: unset !important;
        }

        div.lhnWindow.lhnActive.circleButton#lhnHelpOutCenter {
            bottom: 0px !important;
        }

        div.lhnWindow.lhnleft#lhnHelpOutCenter {
            left: 0px !important;
        }

        div#lhnHocButton {
            display: none !important;
        }

        /*.lhnWindow {
            position: unset !important;
            max-height: 100% !important
        }*/

        /*.sedgwick-livechat-containere {
            width: 100% !important;
            height: 100% !important;
            font-family: 'Calibri','Helvetica Neue','Arial','sans-serif' !important;
        }*/

        div.lhnWindow-header {
            background-color: #007DBC !important;
        }

        div.lhnChatActionsMenu img {
            background-color: #007DBC !important;
        }

        div.lhnPrompt button.lhnFormButton {
            background-color: #007DBC !important;
        }

        button.lhnPromptDecline {
            display: none;
        }

        button.lhnSubmit.hocPrechat.lhnFormButton {
            display: none;
        }

        div#lhnHocInvite div.lhnHocInviteCont {
            display: none;
        }

        textarea {
            font-family: 'Calibri','Helvetica Neue','Arial','sans-serif' !important;
        }

        .lhnHocEmojiAction {
            display: none;
        }

        #lhnChatMessage {
            cursor: auto;
            caret-color: auto;
        }

        /** LiveHelpNow CSS ends */


        /** Webchat CSS starts */
        .webchatContainer .webchat__bubble__nub-pad {
            width: 0px !important;
        }

        /** Button CSS in adaptive card */
        .webchatContainer .ac-pushButton {
            background-color: #007DBC !important;
            border: 1px solid #007DBC !important;
            border-radius: 5px !important;
            color: #FFFFFF !important;
            font-size: 14px !important;
            font-weight: normal !important;
        }

            .webchatContainer .ac-pushButton:focus {
                background-color: #fff !important;
                color: black !important;
                border: 1px solid #007DBC !important;
                outline: none !important;
            }

            .webchatContainer .ac-pushButton:hover {
                cursor:pointer !important;
            }

            /** Button pressed CSS in adaptive card */
            .webchatContainer .ac-pushButton[aria-pressed="true"] {
                background-color: #FFFFFF !important;
                border: 1px solid #D2D8E1 !important;
                border-radius: 5px !important;
                color: #000000 !important;
                font-size: 14px !important;
                font-weight: normal !important;
            }

        .webchatContainer {
            font-family: 'Calibri','Helvetica Neue','Arial','sans-serif' !important;
            font-size: 15px;
            align-items: center;
            background-color: #f9f9f9;
            border-radius: 4px;
            bottom: 10%;
            height: 60%;
            max-height: 575px;
            min-height: 450px;
            justify-content: space-between;
            opacity: 1;
            position: fixed;
            width: 375px;
            z-index: 1000002;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            tab-size-webkit-filter: drop-shadow(0 5px 16px rgba(0,0,0,.22));
            filter: drop-shadow(0 5px 16px rgba(0,0,0,.22));
        }

            .webchatContainer.align-right {
                right: 20px;
            }

            .webchatContainer .webchatheader {
                background-color: #007DBC;
                box-shadow: 0 1px rgba(0, 0, 0, 0.2);
                box-sizing: content-box;
                color: #ffffff;
                font-weight: 500;
                height: 12%;
                left: 0;
                letter-spacing: 0.5px;
                padding: 20px 20px 0 20px;
                position: relative;
                right: 0;
                top: 0;
                z-index: 1;
                border-radius: 4px !important;
            }

            .webchatContainer .webchatbody {
                position: relative;
                height: 88%;
                border-radius: 4px !important;
            }

            .webchatContainer .webchat {
                height: 100%;
                border: 1px solid grey;
            }

            .webchatContainer .webchat-header-closer {
                position: static;
                right: 15px;
                text-align: right;
                top: 19px;
            }

            .webchatContainer .wc-session-expiry-content {
                background-color: #ff0000;
                color: #ffffff;
                letter-spacing: 0.5px;
                font-size: 12px;
                padding: 5px;
                position: absolute;
                left: 0;
                right: 0;
                z-index: 10;
                border-radius: 5px;
            }

            .webchatContainer .modal {
                display: none;
                position: fixed;
                z-index: 1;
                top: 0;
                left: 0;
                right: 0;
                text-align: center;
                height: 106%;
                overflow: auto;
                background-color: black;
                background-color: rgba(0, 0, 0, 0.4);
            }

                .webchatContainer .modal .modal-content {
                    background-color: #fefefe;
                    margin: auto;
                    padding: 20px;
                    border: 1px solid #888;
                    width: 80%;
                    top: 20%;
                    position: relative;
                    border-radius: 5px;
                    text-align: -webkit-center !important;
                }

                .webchatContainer .modal .wc-app button {
                    background-color: #007DBC;
                    border: 1px solid #cccccc;
                    border-radius: 1px;
                    color: #ffffff;
                    cursor: pointer;
                    transition: color .2s ease, background-color .2s ease;
                }

                .webchatContainer .modal button {
                    -webkit-box-align: center;
                    align-items: center;
                    background-color: rgb(0, 125, 188);
                    border-color: rgb(0, 125, 188);
                    border-radius: 3px;
                    border-style: solid;
                    border-width: 1px;
                    color: rgb(255, 255, 255);
                    font-family: 'Calibri','Helvetica Neue','Arial','sans-serif' !important;
                    font-size: inherit;
                    height: 30px;
                    -webkit-box-pack: center;
                    justify-content: center;
                    max-width: 100%;
                    outline: 0px;
                    padding-left: 20px;
                    padding-right: 20px;
                    position: relative;
                    white-space: nowrap;
                    width: 100px;
                }

                    .webchatContainer .modal button:focus {
                        background-color: #fff;
                        color: black;
                    }

            .webchatContainer .web-chat-container-chatbox-classic-header-top-properties {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                cursor: pointer;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-direction: column;
                flex-direction: column;
                gap: 4px;
                position: relative
            }

            .webchatContainer .webWindow-header-closer {
                position: static;
                right: 15px;
                text-align: center;
                top: 19px
            }

                .webchatContainer .webWindow-header-closer svg {
                    background-color: #fff;
                    border-radius: 50%;
                    display: block
                }

        .thumbs-up {
            cursor: pointer;
            display: block;
            width: 40px;
        }

        .thumbs-down {
            cursor: pointer;
            display: block;
            width: 40px;
        }

        .feedback-action-dv {
            display: inline-flex !important;
        }

        #txtfeedback {
            border: 1px solid black !important;
            width:90% !important;
        }

        #dvfeedback{
            width:90%;
        }

        .webchat__suggested-actions__item-box {
            display: inline-block !important;
        }

        .webchat__suggested-actions__button {
            width: 150px;
        }

            .webchat__suggested-actions__button:hover {
                color: #fff !important;
                cursor: pointer !important;
                border: 1px solid #007DBC !important;
                background-color: #007DBC !important;
            }


        /** Webchat CSS ends */
    </style>`);
    (async function (data) {
      const res = await fetch(_chatconfig.webchat.baseApiUrl + "GenerateToken", { method: "POST", headers: headers, body: JSON.stringify(data) });
      const response = await res.json();
      _liveChatConfig = response.liveChatConfig;

      _liveChatConfiguration = response.session.liveChatFieldsConfiguration;

      _loggOffData = { UserId: response.session.userId, ConversationId: response.session.conversationId };
      sessionTimeOut = response.session.sessionTimeout;
      reminderInterval = response.session.reminderInterval;
      _directLineUrl = response.session.directLineUrl;
      instrumentationkey = response.session.appInsightsInstrumentationKey;
      isSurveyEnabled = response.session.isSurveyAccess;
      generateDom();
      createStore();
      window.WebChat.renderWebChat({
        directLine: await window.WebChat.createDirectLineAppServiceExtension({
          domain: _directLineUrl + '/directline',
          token: response.session.bot_token
        }), styleOptions: _styleOptions, store, userid: data.useId
      }, document.getElementById('webchat'));
      DisableSendBox();
      FocusSendBox();
      //document.querySelector('#webchat > *').focus();
      _sessionActive = true;
    })(data).catch((err) => {
      console.error('Directline Error == ' + err);
      DisableSendBox();
      IntimateBotOfClientError();
    });
  };
  const _endConversation = () => {
    if (!store) return;

    (async function (_loggOffData) {
      DisableSendBox();
      const res = await fetch(_chatconfig.webchat.baseApiUrl + "SignOff", { "method": "POST", "headers": headers, "body": JSON.stringify(_loggOffData) });
      setTimeout(function () {
        _sessionActive = false;
        store.dispatch({ type: 'DIRECT_LINE/DISCONNECT', payload: { text: `Reset` } });
        clearDom();
      }, 1000);
      return true;
    })(_loggOffData).catch((err) => {
      console.error(err)
    });
  }

  return {
    config(config) {
      _chatconfig = config;
    },
    initChat: _initChat,
    endConversation: _endConversation,
    isConversationOn() {
      return _sessionActive;
    }
  }
})();
